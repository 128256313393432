/// <reference types="vite/client" />
import {
	ApolloLink,
	ApolloClient,
	InMemoryCache,
	NormalizedCacheObject,
	split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { App, Plugin } from 'vue';
import { useConnectionStore } from './connectionStore';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore vue-tsc doesn't like js, because we don't enable it globally
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import introspection from './introspectionResult';
import { Pinia } from 'pinia';
import { withScalars } from 'apollo-link-scalars';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import { typesMap } from '@compenda/vue/data-graphql';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { v4 as uuidv4 } from 'uuid';
import schemaJson from '../graphql.schema.json';

export * from './connectionStore';
export * from './composables/useSwitchClient';

function useGetAuthHeader(pinia?: Pinia) {
	const connectionStore = useConnectionStore(pinia);

	async function getAuthHeader(
		{ mayRefresh }: { mayRefresh: boolean } = { mayRefresh: true },
	): Promise<string | undefined> {
		const t = connectionStore.getToken();
		if (t) return `Bearer ${t}`;
		if (mayRefresh) {
			const { ok } = await connectionStore.refresh();
			if (ok) return await getAuthHeader({ mayRefresh: false });
		}
		return undefined;
	}

	return { getAuthHeader };
}

export function createApolloClient(pinia?: Pinia, skipAuth = false) {
	const schema = buildClientSchema(schemaJson as unknown as IntrospectionQuery);
	const scalarLink = withScalars({ schema, typesMap });

	const { getAuthHeader } = useGetAuthHeader(pinia);
	const connectionStore = useConnectionStore(pinia);

	const compendaSessionID = uuidv4();

	const authLink = setContext(async (_, { headers }) => {
		// return the headers to the context so httpLink can read them
		if (skipAuth)
			return {
				headers: {
					...headers,
					'Compenda-Session-ID': compendaSessionID,
				},
			};
		return {
			headers: {
				...headers,
				'Compenda-Session-ID': compendaSessionID,
				Authorization: await getAuthHeader(),
			},
		};
	});

	const httpLink = createUploadLink({
		uri: () =>
			connectionStore.clients[connectionStore.currentClientName].endpoint +
			'/query',
	});

	const getWsUri = () => {
		if (import.meta.env.DEV) {
			return 'ws://localhost:18081/api/query';
		}
		const endpoint =
			connectionStore.clients[connectionStore.currentClientName].endpoint +
			'/graphql';
		// if the endpoint is relative, use the current host and detect if it is http or https and use ws or wss accordingly
		if (endpoint.startsWith('/')) {
			const protocol = window.location.protocol;
			return `${protocol === 'http:' ? 'ws' : 'wss'}://${
				window.location.host
			}${endpoint}`;
		}
		return endpoint.replace(/^http/, 'ws');
	};

	const wsClient = createClient({
		shouldRetry: () => true,
		retryAttempts: Infinity,
		url: getWsUri,
		connectionParams: async () => {
			const token = await getAuthHeader();
			return token
				? { Authorization: token, 'Compenda-Session-ID': compendaSessionID }
				: {};
		},
	});
	const wsLink = new GraphQLWsLink(wsClient);

	// using the ability to split links, you can send data to each link
	// depending on what kind of operation is being sent
	const link = split(
		// split based on operation type
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
				definition.kind === 'OperationDefinition' &&
				definition.operation === 'subscription'
			);
		},
		// if its a subscription, use the wsLink, which handles it's own authentication
		ApolloLink.from([scalarLink, wsLink]),
		// otherwise use the http link. The http link is split into two links
		// the authLink and the httpLink
		ApolloLink.from([scalarLink, authLink, httpLink]),
	);

	// Cache implementation
	const cache = new InMemoryCache({
		possibleTypes: introspection.possibleTypes,
	});

	// Create the apollo client
	const apolloClient = new ApolloClient({
		link,
		cache,
		defaultOptions: {
			query: {
				fetchPolicy: 'network-only',
			},
			watchQuery: {
				fetchPolicy: 'cache-and-network',
			},
		},
	});

	return apolloClient;
}

let defaultClient: ApolloClient<NormalizedCacheObject>;

export function getDefaultClient(
	skipAuth?: boolean,
): ApolloClient<NormalizedCacheObject> {
	if (!defaultClient) defaultClient = createApolloClient(undefined, skipAuth);
	return defaultClient;
}

export const apollo: Plugin = {
	install(
		app: App,
		client?: ApolloClient<NormalizedCacheObject>,
		skipAuth = false,
	) {
		if (client === undefined) client = getDefaultClient(skipAuth);
		app.provide(DefaultApolloClient, client);
	},
};
